@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.container {
  margin: rem(30) auto rem(120);

  p,
  th,
  td {
    font-size: rem(14);
    line-height: rem(21);
    margin-bottom: rem(24);
  }

  h3 {
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(30);
    margin-bottom: rem(24);
  }

  ul {
    padding-left: rem(20);
    margin-bottom: rem(24);
  }

  table {
    border-collapse: collapse;
    margin-bottom: rem(24);
    ul {
      margin-bottom: 0;
    }
  }

  th,
  td {
    vertical-align: top;
    border: 1px solid $black_100;
    padding: rem(10);
  }

  :global .table-wrapper {
    overflow-x: scroll;
  }
}

.title {
  font-size: rem(32);
  line-height: rem(38);
  margin-bottom: rem(24);
}

@media (min-width: 768px) {
  .title {
    font-size: rem(56);
    line-height: rem(67);
  }
  .container {
    max-width: 874px;
    margin: 0 auto rem(165);

    :global .table-wrapper {
      overflow-x: auto;
    }

    p,
    th,
    td {
      font-size: rem(16);
      line-height: rem(24);
      margin-bottom: rem(32);
    }
    th,
    td {
      vertical-align: middle;
    }

    h3 {
      font-size: rem(24);
      line-height: rem(36);
      margin-bottom: rem(32);
    }
  }
}
