@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  background-color: $gray_50;
  padding: rem(40) rem(10) rem(73);
  margin: 0 rem(-10);
}

.title {
  font-size: rem(32);
  line-height: rem(38);
}

.paragraph {
  margin: rem(24) 0 0;
  font-size: rem(18);
  line-height: rem(29);
  color: $black_100;

  p {
    margin-bottom: rem(15);
  }
}

.image {
  width: 100%;
  margin-top: rem(40);
}

.row {
  display: flex;
}

@media (min-width: 768px) {
  .col {
    width: 50%;
  }
  .container {
    background-color: $gray_50;
    padding: rem(64) rem(64) rem(73);
    margin: 0;
  }
  .title {
    font-size: rem(56);
    line-height: rem(67);
  }
  .paragraph {
    margin: rem(24) 0 rem(40);
  }
  .image {
    margin-top: rem(100);
  }
}
