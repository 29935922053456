@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.footer {
  background-color: $black_100;
  padding: rem(60) 0 rem(24);
  color: $white_100;
  font-family: "Lato";
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(21);

  a {
    position: relative;
    text-decoration: none;
    color: $white_100;
  }

  a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -4px;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  a:hover:before {
    visibility: visible;
    width: 100%;
  }
}

.logo {
  max-width: 134px;
}

.offices {
  flex-direction: column;
  display: flex;
  margin: rem(30) 0 rem(82);

  & > * {
    width: 100%;
    margin-top: rem(35);
  }
}

.office-title {
  font-family: "Lato";
  font-weight: 700;
  font-size: rem(11);
  line-height: rem(13);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $gray_100;
  margin-bottom: rem(10);
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contacts {
  a {
    display: inline-block;
  }
}

.linked-in {
  margin-bottom: rem(25);
}

.footer-info-left,
.footer-info-right {
  display: flex;
}

.footer-info-left {
  & > * {
    margin-right: rem(15);
    &:last-child {
      margin-right: rem(0);
    }
  }
  // gap: rem(15);
  align-items: center;
}

.footer-info-right {
  display: none;
}

@media only screen and (min-width: 768px) {
  .linked-in {
    margin-bottom: 0;
  }
  .footer {
    padding: rem(60) 0 rem(40);
  }
  .contacts-col {
    display: none;
  }
  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // gap: rem(48);
    & > * {
      margin-right: rem(48);
      &:last-child {
        margin-right: rem(0);
      }
    }
  }
  .footer-info,
  .offices {
    flex-direction: row;
  }
  .footer-info-right {
    display: block;
  }
  .offices {
    margin: rem(30) 0 rem(150);
    & > * {
      width: 312px;
      margin-top: 0;
    }
  }
  .footer-info-left {
    //gap: rem(20);
    & > * {
      margin-right: rem(20);
      &:last-child {
        margin-right: rem(0);
      }
    }
  }
}
