@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  padding: rem(35) 0 rem(24);
}

.row {
  display: flex;
  flex-direction: column;
}

.paragraph {
  margin: rem(24) 0 rem(20);
  font-size: rem(16);
  line-height: rem(26);
  color: $black_100;

  p {
    margin-bottom: rem(15);
  }
}

.image {
  display: none;
}

.button {
  margin-top: rem(4);
}

@media only screen and (min-width: 768px) {
  .container {
    padding: rem(70) 0 rem(80);
    min-height: 509px;
  }
  .button {
    margin-top: 0;
  }
  .image {
    width: 100%;
    max-width: 300px;
    margin-top: rem(42);
    display: block;
  }
  .paragraph {
    margin: rem(24) 0 rem(40);
    font-size: rem(18);
    line-height: rem(29);
  }
  .row {
    flex-direction: row;
  }
  .col {
    width: 50%;
  }

  .col-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
