@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  padding: rem(60) 0 rem(120);
}

.title {
  margin-bottom: rem(24);
}

.row {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: rem(16);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .container {
    padding: rem(70) 0 rem(165);
  }
  .row {
    flex-direction: row;
    & > * {
      margin-right: rem(84);
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .title {
    margin-bottom: rem(40);
  }

  .col {
    width: 50%;
  }

  .box {
    min-height: 361px;
  }
}
