@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  padding: rem(80) 0 rem(60);
}

.title {
  margin-bottom: rem(32);
  font-size: rem(24);
  line-height: rem(29);
}

.row {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: rem(40);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.col-title {
  font-family: "Alata";
  font-size: rem(24);
  line-height: rem(29);
  margin-bottom: rem(16);
}

.col-text {
  font-size: rem(16);
  line-height: rem(26);
}

@media (min-width: 768px) {
  .col-text {
    font-size: rem(18);
    line-height: rem(29);
  }
  .row {
    // gap: rem(84);
    flex-direction: row;
    & > * {
      margin-right: rem(84);
      margin-bottom: 0;
      &:last-child {
        margin-right: rem(0);
      }
    }
  }
  .col {
    width: 50%;
  }
  .container {
    padding: rem(160) 0 rem(80);
  }
  .title {
    margin-bottom: rem(40);
    font-size: rem(56);
    line-height: rem(67);
  }
  .col-title {
    font-family: "Alata";
    font-size: rem(32);
    line-height: rem(38);
    margin-bottom: rem(24);
    min-height: 112px;
  }
}
