@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.button {
  display: inline-block;
  color: $white_100;
  background-color: $acsent_100;
  border-radius: 60px;
  font-family: "Lato";
  font-weight: 700;
  font-size: rem(11);
  line-height: rem(13);
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: rem(21) rem(37);
  border: 1px solid $acsent_100;
  height: 56px;
  text-decoration: none;
  transition: all ease-out 300ms;
  &:disabled {
    opacity: 0.4;
    &:hover {
      cursor: not-allowed;
      color: $white_100;
      background-color: $acsent_100;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(199, 140, 87, 0.1);
    color: $acsent_100;
  }
}
