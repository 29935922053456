@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.dialog-icon {
  margin-bottom: rem(16);
}

.dialog-title {
  font-family: "Alata";
  font-weight: 400;
  font-size: rem(32);
  line-height: rem(48);
  margin-bottom: rem(6);
}

.row {
  display: flex;
  flex-direction: column;
}

.label {
  display: block;
  font-family: "Lato";
  font-weight: 500;
  font-size: rem(11);
  line-height: rem(13);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $gray_70;
  margin-bottom: rem(12);

  &.label-inline {
    display: inline-block;
    margin-bottom: rem(0);
    text-transform: none;
    color: $black_100;
    font-size: rem(16);
    line-height: rem(26);
    letter-spacing: unset;
    &:hover {
      cursor: pointer;
    }
    a {
      color: $acsent_100;
      position: relative;
      text-decoration: none;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background-color: $acsent_100;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.input,
.textarea {
  font-family: "Lato";
  background: $white_100;
  border: 1px solid $gray_90;
  height: 48px;
  width: 100%;
  padding: rem(12);
  margin-bottom: rem(24);
  outline: none;
  &:focus {
    border-color: $gray_100;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: rem(27);
}

.input-error {
  border-color: $error;
}

.textarea {
  height: 144px;
}

.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem(153);
  &.submitting {
    justify-content: space-between;
  }
  & > * {
    margin-right: rem(10);
    &:last-child {
      margin-right: rem(0);
    }
  }
}

@media (min-width: 768px) {
  .input,
  .textarea {
    margin-bottom: rem(40);
  }
  .row {
    & > * {
      margin-right: rem(64);
      &:last-child {
        margin-right: rem(0);
      }
    }
    flex-direction: row;
  }
  .col {
    width: 50%;
  }
}
