@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.wrapper {
  padding: rem(70) 0 0;
}

.container {
  padding: 0;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
  text-align: center;
  background-image: url("../../../public/assets/images/testimonial_bg-mobile.png");
  height: 355px;
  background-size: cover;
}

.text {
  font-family: "Alata";
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(29);
  margin-bottom: rem(24);
  color: $white_100;
}

.author {
  .name {
    font-family: "Lato";
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(21);
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $gray_50;
  }

  .profession {
    font-family: "Lato";
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(21);
    letter-spacing: 0.15em;
    color: $gray_70;
  }
}

.col {
  max-width: 875px;
  margin: 0 auto;
  padding: 0 rem(20);
}

@media (min-width: 768px) {
  .wrapper {
    padding: rem(70) 0 rem(80);
  }
  .col {
    padding: 0;
  }
  .text {
    font-size: rem(32);
    line-height: rem(48);
  }
  .row {
    background-image: url("../../../public/assets/images/testimonial_bg.png");
    height: 440px;
    padding: 0 rem(24px);
  }
}
