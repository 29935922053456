@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.wrapper {
  padding: rem(5);
  margin: rem(-5);

  &:hover {
    cursor: pointer;
    border-color: $gray_100;
  }
}

.checkbox {
  justify-content: center;
  width: rem(16);
  height: rem(16);
  margin-right: rem(8);
  transition: 200ms all;
  border: 1px solid $gray_90;
  flex-shrink: 0;
  background: $white_100;
  position: relative;

  &.checked {
    border-color: $black_100;
    background: $black_100;
  }

  &.invalid {
    border-color: $error;
  }
}

.icon {
  position: absolute;
  top: 1px;
  left: 0;
}
