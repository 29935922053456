@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  padding: rem(60) 0 rem(80);
}

.row {
  display: flex;
  flex-direction: column;
}

.paragraph {
  margin: rem(16) 0 rem(20);
  font-size: rem(16);
  line-height: rem(26);
  color: $black_100;

  p {
    margin-bottom: rem(15);
  }
}

.col-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.image {
  width: 100%;
  max-width: 570px;
  float: right;
}

@media only screen and (min-width: 768px) {
  .container {
    padding: rem(85) 0 rem(40);
  }
  .paragraph {
    margin: rem(24) 0 rem(40);
    font-size: rem(18);
    line-height: rem(29);
  }
  .row {
    flex-direction: row;
  }
  .col {
    width: 50%;
  }
}
