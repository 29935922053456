@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.wrapper {
  background-image: url("../../../public/assets/images/hero-mobile.png");
  height: 670px;
  color: $white_100;
  background-position: bottom;
  position: relative;
  overflow: hidden;
  background-size: cover;
}

.circle-1,
.circle-2 {
  mix-blend-mode: color-dodge;
  position: absolute;
}

.circle-1 {
  width: 167px;
  height: 167px;
  right: 26px;
  bottom: 33px;
  animation: circle_1_move 25s linear infinite;
}

@keyframes circle_1_move {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0) translateY(-100px);
  }
}

.circle-2 {
  width: 135px;
  height: 135px;
  right: -41px;
  bottom: 119px;
  animation: circle_2_move 25s linear infinite;
}

@keyframes circle_2_move {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0) translateY(50px);
  }
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 rem(15);
  margin: 0 auto;
  position: relative;
}

.title {
  font-family: "Alata";
  font-style: normal;
  font-weight: 400;
  font-size: rem(32);
  line-height: rem(38);
  margin-bottom: rem(16);
}

.subtitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(22);
  margin-bottom: rem(24);
}

.col {
  max-width: 713px;
}

@media (min-width: 768px) {
  .container {
    //max-width: 800px;
    padding: 0 rem(40);
  }
  .circle-1 {
    width: 305px;
    height: 305px;
    right: 243px;
    bottom: 65px;
  }
  .circle-2 {
    width: 248px;
    height: 248px;
    right: 120px;
    bottom: 223px;
  }
  .wrapper {
    background-image: url("../../../public/assets/images/hero.png");
  }
  .title {
    font-size: rem(56);
    line-height: 120%;
    margin-bottom: rem(24);
  }
  .subtitle {
    font-size: rem(18);
    line-height: 160%;
    margin-bottom: rem(48);
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1440px;
    padding: 0 rem(60);
  }
}
