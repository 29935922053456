@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.header {
  width: 100%;
  z-index: 1;

  &.home-layout {
    position: absolute;
    top: 0;
    padding-top: rem(20);
    left: 0;
    a {
      color: $black_100;
    }
  }

  &.common-layout {
    padding: rem(20) 0 rem(28);

    a {
      color: $black_100;
    }
  }

  .nav {
    a {
      transition: ease-out 300ms;
      text-decoration: none;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.logo {
  max-height: rem(24);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-hidden {
  display: none;
}

.menu-button,
.close-button {
  background-color: transparent;
  border: none;
}

.close-button {
  position: absolute;
  right: rem(20);
  top: rem(26);
  z-index: 1;
}

.nav {
  text-transform: uppercase;
  position: absolute;
  background-color: $white_100;
  left: 0;
  top: 0;
  width: 100%;
  padding: rem(60) 0;

  ul {
    display: flex;
    //gap: rem(32);
    flex-direction: column;
    & > * {
      margin-bottom: rem(32);
      &:last-child {
        margin-bottom: rem(0);
      }
    }
  }

  li {
    font-family: "Lato";
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(13);
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    padding: 0 rem(30);
  }
}

@media (min-width: 768px) {
  .logo {
    max-height: unset;
  }
  .row {
    min-height: 49px;
  }
  .menu-button,
  .close-button {
    display: none;
  }
  .nav-hidden {
    display: block;
  }

  .header {
    &.home-layout {
      padding-top: rem(42);

      a {
        color: $white_100;
      }
    }
    &.common-layout {
      padding: rem(42) 0 rem(50);
    }
  }

  .menu-icon {
    display: none;
  }

  .nav {
    padding: 0;
    position: relative;
    background-color: transparent;

    ul {
      flex-direction: row;
      // gap: unset;
      & > * {
        margin-bottom: rem(0);
      }
    }

    li {
      display: inline-block;

      &:last-child {
        padding: 0 0 0 rem(30);
      }
    }
  }
}
