@keyframes rotator-keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash-keyframes {
  0% {
    stroke-dashoffset: 180;
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 25;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 180;
    transform: rotate(360deg);
  }
}

.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-section {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg {
  animation: rotator-keyframes 1.4s linear infinite;
}

.spinner-path {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash-keyframes 1.4s linear infinite;
}
