@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.title {
  font-family: "Alata";
  font-size: rem(24);
  font-weight: 400;
  line-height: rem(29);
  color: $black_100;
}

.subtitle {
  font-family: "Lato";
  font-weight: 500;
  font-size: rem(11);
  line-height: rem(13);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $gray_100;
  margin-bottom: rem(10);
}

@media (min-width: 768px) {
  .title {
    font-size: rem(40);
    line-height: rem(48);
  }
}
