@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  padding: rem(80) 0 rem(60);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(40);
  align-items: center;
}

.row {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: rem(16);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title-button {
  display: none;
}
.button {
  margin-top: rem(16);
}
.box-risk {
  margin-bottom: rem(16);
}

@media only screen and (min-width: 768px) {
  .container {
    padding: rem(160) 0 rem(86);
  }
  .box {
    min-height: rem(265);
    padding: rem(40) rem(32) rem(27) rem(40) !important;
  }
  .box-risk {
    margin-bottom: rem(84);
  }
  .box-big {
    padding: rem(52) rem(64) rem(28) rem(60) !important;
    min-height: rem(614);
    margin-bottom: 0;
    :global .title {
      font-size: rem(40);
      line-height: rem(48);
    }
  }
  .row {
    flex-direction: row;
    & > * {
      margin-right: rem(84);
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .col {
    width: 50%;
  }
  .title-button {
    display: block;
  }
  .button {
    display: none;
  }
}
