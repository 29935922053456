@import "./utils/styles/functions.module.scss";

.container-local {
  padding: 0 rem(20);
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .container-local {
    //max-width: 800px;
    padding: 0 rem(40);
  }
}

@media only screen and (min-width: 992px) {
  .container-local {
    max-width: 1440px;
    padding: 0 rem(60);
  }
}
