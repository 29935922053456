@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.wrapper {
  padding: 0 0 rem(120);
}

.container {
  background-color: $gray_50;
  padding: rem(40) rem(10) rem(72);
  margin: 0 rem(-10);
}

.title {
  font-size: rem(32);
  line-height: rem(38);
  margin-bottom: rem(24);
}

.paragraph,
.paragraph-top {
  margin: rem(24) 0 rem(40);
  font-size: rem(18);
  line-height: rem(29);
  color: $black_100;

  p {
    margin-bottom: rem(15);
  }
}

.paragraph-top {
  font-size: rem(16);
  line-height: rem(26);
}

.row {
  display: flex;
  flex-direction: column;
}

.col-left {
  flex: 0 0 auto;
}
.col-right {
  display: none;
}

.contacts-title {
  font-size: rem(24);
  line-height: rem(24);
  margin-bottom: rem(16);
}

.contacts-address,
.contacts-country,
.contacts-email,
.contacts-phone-number {
  font-size: rem(18);
  line-height: rem(29);
  margin-bottom: rem(6);
}
.contacts-email {
  a {
    color: $acsent_100;
    text-decoration: none;
  }
}
.contacts-phone-number {
  margin-bottom: rem(48);
  a {
    color: $black_100;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .wrapper {
    padding: 0 0 rem(165);
  }
  .paragraph-top {
    display: none;
  }
  .title {
    font-size: rem(56);
    line-height: rem(67);
    margin-bottom: rem(56);
  }
  .container {
    padding: rem(85) rem(64) rem(57);
    margin: 0;
  }
  .col-left {
    flex: 0 0 762px;
  }

  .col-right {
    display: flex;
    align-items: center;
  }
  .row {
    // gap: rem(64);
    flex-direction: row;
    & > * {
      margin-right: rem(131);
      &:last-child {
        margin-right: rem(0);
      }
    }
  }
}
