@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.wrapper {
  background-color: $gray_50;
  padding: rem(32) rem(20) rem(48);
}

.icon {
  background-color: $gray_50;
  height: 32px;
  margin-bottom: rem(24);
}

.title {
  font-family: "Alata";
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(24);
  color: $black_100;
  margin-bottom: rem(16);
}

.text {
  font-family: "Lato";
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(26);
  color: $black_100;
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: rem(24);
    line-height: rem(29);
  }
  .text {
    font-size: rem(18);
    line-height: rem(29);
  }
  .wrapper {
    padding: rem(32) rem(40) rem(48);
  }

  .icon {
    margin-bottom: rem(30);
  }
}
