@import "../../../../utils/styles/variables.module.scss";
@import "../../../../utils/styles/functions.module.scss";

.container {
  overflow: hidden;
  margin: rem(40) 0;
}

.row {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  & > * {
    margin-right: rem(40);
    &:last-child {
      margin-right: rem(0);
    }
  }

  img {
    max-height: 30px;
    max-width: 120px;
    object-fit: contain;
    &:first-child {
      margin-left: rem(20);
    }
    &:last-child {
      margin-right: rem(20);
    }
  }
}

.row::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

@media only screen and (min-width: 768px) {
  .container {
    //max-width: 800px;
    padding: 0 rem(40);
    margin: 0 auto;
  }
  .row > * + * {
    margin-right: rem(109);
  }

  .row {
    & > * {
      margin-right: rem(109);
      &:last-child {
        margin-right: rem(0);
      }
    }

    img {
      max-height: 40px;
      max-width: 166px;
      &.citadele {
        max-height: 43px;
      }
      &.bfjord {
        max-height: 38px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1440px;
    padding: 0 rem(60);
  }
}
