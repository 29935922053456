@import "../../utils/styles/variables.module.scss";
@import "../../utils/styles/functions.module.scss";

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.menu-button,
.close-button {
  background-color: transparent;
  border: none;
}

.close-button {
  position: absolute;
  right: rem(26);
  top: rem(26);
  z-index: 1;
  cursor: pointer;
  outline: none;
}

.dialog {
  z-index: 10;
  position: relative;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  & > div {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.dialog-panel {
  padding: rem(40) rem(24) rem(48);
  background-color: $white_100;
  position: relative;
  height: 100vh;

  p {
    font-weight: 400;
    font-size: rem(18);
    line-height: rem(29);
    margin-bottom: rem(24);
  }
}

@media (min-width: 560px) {
  .dialog-panel {
    height: auto;
    min-width: rem(560);
  }
}
